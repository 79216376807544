<template>
  <div
    class="d-flex"
    style="gap: 36px"
    :style="$vuetify.breakpoint.smAndDown && 'flex-direction: column'"
  >
    <div class="d-flex" style="gap: 36px; flex-direction: column" v-if="$vuetify.breakpoint.lgAndUp">
      <profile-card :userData="userData" />
      <maker-card :userData="userData" />
      <company-card :userData="userData" />
    </div>
    <div style="width: 100%">
      <order-card :userData="userData" :fullscreen="true" />
    </div>
  </div>
</template>

<style scoped></style>

<script>
import ProfileCard from "./components/ProfileCard.vue";
import MakerCard from "./components/MakerCard.vue";
import CompanyCard from "./components/CompanyCard.vue";
import OrderCard from "./components/OrderCard.vue";

export default {
  components: { ProfileCard, MakerCard, CompanyCard, OrderCard },
  name: "MyPage-MyOrders",
  props: { userData: Object, orderData: Array },
  data() {
    return {};
  },
};
</script>
